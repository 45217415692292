import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

import jsonData from "../components/Contents/ServiceContent.json";

import heroImage from '../images/CorporateEvent/CorporateBanner.png'
import Conferences from '../images/CorporateEvent/Conferences.png'
import CompanyParty1 from '../images/CorporateEvent/CompanyParty1.png'
import CompanyParty2 from '../images/CorporateEvent/CompanyParty2.png'
const lang = 'de'

function CorporateEvents() {
  const [ userLanguage, setUserLanguage ] = useState (lang);

  useEffect(() => {
    localStorage.getItem('lang') ? setUserLanguage(localStorage.getItem('lang')) : setUserLanguage(lang);
  }, [])

  return (
    <Container
      fluid
      style={{
        backgroundColor: "#1a1718",
        marginBottom: "0",
        maxWidth: "100%",
        paddingRight: "0",
        paddingLeft: "0",
      }}
    >
      <NavBar  setLanguage={setUserLanguage} userLanguage={userLanguage} />

      <div
        id="hero"
        style={{
          backgroundImage: `url(${heroImage})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="hero-content-other py-2" data-aos="fade-up">
          <h2 className="font-effect-anaglyph my-2">
            {userLanguage==='en' ? jsonData.engCorporate.title : (userLanguage==='de' ? jsonData.deCorporate.title : jsonData.trCorporate.title)}
          </h2>
        </div>
      </div>

      <div
        className="product-desciption"
        style={{ backgroundColor: "#eeeeee" }}
      >
        <Container
          style={{
            maxWidth: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Row className="">
            <Col md={6} className="product-image-left">
              <img
                src={Conferences}
                width="80%"
                height="80%"
                className="d-inline-block"
                alt="conference"
              />
            </Col>
            <Col md={6}>
            <div className="px-5 product-description" >
                <h2>
                {userLanguage==='en' ? jsonData.engCorporate.head1 : (userLanguage==='de' ? jsonData.deCorporate.head1 : jsonData.trCorporate.head1)}
                </h2>
                <p>
                {userLanguage==='en' ? jsonData.engCorporate.paragraph1 : (userLanguage==='de' ? jsonData.deCorporate.paragraph1 : jsonData.trCorporate.paragraph1)}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        className="product-desciption"
        style={{ backgroundColor: "#f3eeb5" }}
      >
        <Container
          style={{
            maxWidth: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Row className="">
            <Col md={6}>
            <div className="px-5 product-description" >
            <h2>
                {userLanguage==='en' ? jsonData.engCorporate.head2 : (userLanguage==='de' ? jsonData.deCorporate.head2 : jsonData.trCorporate.head2)}
                </h2>
                <p>
                {userLanguage==='en' ? jsonData.engCorporate.paragraph2 : (userLanguage==='de' ? jsonData.deCorporate.paragraph2 : jsonData.trCorporate.paragraph2)}
                </p>
              </div>
            </Col>
            <Col md={6} className="product-image-right">
              <img
                src={CompanyParty1}
                width="80%"
                height="80%"
                className="d-inline-block"
                alt="party 1"
              />
            </Col>
          </Row>
        </Container>
      </div>


      <div
        className="product-desciption"
        style={{ backgroundColor: "#eeeeee" }}
      >
        <Container
          style={{
            maxWidth: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Row className="">
            <Col md={6} className="product-image-left">
              <img
                src={CompanyParty2}
                width="80%"
                height="80%"
                className="d-inline-block"
                alt="party 2"
              />
            </Col>
            <Col md={6}>
            <div className="px-5 product-description" >
            <h2>
                {userLanguage==='en' ? jsonData.engCorporate.head3 : (userLanguage==='de' ? jsonData.deCorporate.head3 : jsonData.trCorporate.head3)}
                </h2>
                <p>
                {userLanguage==='en' ? jsonData.engCorporate.paragraph3 : (userLanguage==='de' ? jsonData.deCorporate.paragraph3 : jsonData.trCorporate.paragraph3)}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </Container>
  );
}

export default CorporateEvents